import './App.css';

function App() {
  const n = new Date();
  const l = [
    [1, 12], [1, 26], [2, 9], [2, 23], [3, 8], [3, 22],
    [4, 5], [4, 19], [5, 3], [5, 17], [5, 31], [6, 14],
    [6, 28], [7, 12], [7, 26], [8, 9], [8, 23], [9, 6], [9, 20],
    [10, 4], [10, 18], [11, 1], [11, 15], [11, 29], [12, 13],
  ];
  const c = (e) => (new Date(2024, e[0]-1, e[1], 0, 0, 0) <= n
    && n < new Date(2024, e[0]-1, e[1], 14, 0, 0));
  const a = l.some(c);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          {a ? `IGEN` : `NEM`}
        </p>
      </header>
    </div>
  );
}

export default App;
